<template>
  <div class="d-flex align-items-center min-vh-100 login-box">
    <b-container>
      <b-row class="justify-content-center">
        <c-col md="9" lg="6" class="login">
          <b-card class="p-4 card">
            <b-form v-on:submit.prevent="checkForm">
              <div class="text-center mb-3">
                <h1>AZURE AUTH CALLBACK</h1>
              </div>
              <b-row>
                <c-col class="text-center">
                  <b-button to="/welcome" type="button" class="px-4 login-btn"
                    >GO TO LOGIN PAGE</b-button
                  >
                </c-col>
              </b-row>
            </b-form>
          </b-card>
        </c-col>
      </b-row>
    </b-container>
    <Loading />
  </div>
</template>

<script>

export default {
  name: "AzureAuthLogin",
  data() {
    return {
      user: {},
      accessToken: "",
      graphDetails: {},
    };
  },
  methods: {
    resData(accessToken, user, graphDetails) {
      this.accessToken = accessToken;
      this.user = user;
      this.graphDetails = graphDetails;
    },
  },
};
</script>

<style scoped>
.login-box {
  background-color: var(--primary-color);
}
.card {
  width: 100%;
  background-color: #ffffff;
  border: transparent solid 1px;
  /* border-top-left-radius: 20%; */
  /* border-bottom-right-radius: 20%; */
  box-shadow: 0px 4px 15px -3px #7b7b7b;
}

h1 {
  color: var(--primary-color);
}
.login-btn {
  padding: 8px !important;
  font-size: 15px;
  border-radius: 10px !important;
  background: #ffffff;
  border: var(--primary-color) solid 1px;
  color: var(--primary-color);
  text-align: center;
  width: 50%;
  margin: auto;
  transition: 0.3s;
}

.login-btn:hover {
  /* color: var(--primary-color); */
  background-color: var(--primary-color);
  color: #ffffff;
}
.login {
  max-width: 500px;
}

@media (max-width: 600px) {
  .card-body {
    padding: 1.25rem 0;
  }
  .login-btn {
    width: 100% !important;
  }
}
</style>
